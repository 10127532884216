<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{training: $route.params.id, api_key: currentUser.api_key}"
            ref="form">

        <div class="row">
            <validated-date-picker :disabled-date="disableTillToday" class="col-6" label="Start Date"
                                   v-model="model.start_date" format="DD-MM-YYYY" value-type="YYYY-MM-DD"
                                   :rules="rules.start_date" :disabled="loading" @input="setDateLimit(model)"/>

            <validated-date-picker :disabled-date="disabledEndDate" class="col-6" label="End Date"
                                   v-model="model.end_date" format="DD-MM-YYYY" value-type="YYYY-MM-DD"
                                   :rules="rules.end_date" :disabled="loading"/>
        </div>

        <div class="row">
            <validated-input class="col-6" label="Class Start Time" type="time" v-model="model.class_start_time"
                             :rules="rules.class_start_time" :disabled="loading"/>

            <validated-input class="col-6" label="Class End Time" type="time" v-model="model.class_end_time"
                             :rules="rules.class_end_time" :disabled="loading"/>
        </div>

        <div class="row">
            <validated-input class="col-12" label="Location" v-model="model.location"
                             :rules="rules.location" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddSchedule',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl     : urls.admin.event.training.schedule.addEdit,
            start_date : null,
            rules      : {
                start_date : {
                    required : true
                },
                end_date : {
                    required : true
                },
                class_start_time : {
                    required : true
                },
                class_end_time : {
                    required : true
                },
                location : {
                    required : true
                }
            }
        };
    },
    watch : {
        start_date : {
            handler () {
                this.disabledEndDate();
            }
        }
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Training Schedule..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        disabledBeforeToday (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        disableTillToday (date) {
            this.end_date = null;
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);
            return date < tomorrow;
        },

        setDateLimit (model) {
            this.start_date = model.start_date;
            model.end_date = '';
        },

        disabledEndDate (date) {
            let setDate;
            if (this.start_date) {
                setDate = new Date(this.start_date);
                return date < setDate;
            } else {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 2);
                tomorrow.setHours(0, 0, 0, 0);
                return date < tomorrow;
            }
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
