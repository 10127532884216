<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{training: $route.params.id, api_key: currentUser.api_key}">

        <div class="row col-12">
            <validated-input class="col-6" label="Coupon Name" v-model="model.coupon_name"
                             :rules="rules.coupon_name" :disabled="loading"/>

            <validated-input class="col-6" label="Coupon Percentage" v-model="model.coupon_percentage" type="number"
                             :rules="rules.coupon_percentage" :disabled="loading">
                <template #prepend>
                    <span style="font-weight: bold" class="mr-2">%</span>
                </template>
            </validated-input>
        </div>

        <div class="row col-12">
            <validated-date-picker :disabled-date="disabledBeforeToday" class="col-12" label="Expired Date" type="time"
                                   v-model="model.expired_date" :rules="rules.expired_date" :disabled="loading"
                                   format="DD-MM-YYYY" value-type="YYYY-MM-DD"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddCoupon',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.event.training.coupon.addEdit,
            rules  : {
                coupon_name : {
                    required : true
                },
                coupon_percentage : {
                    required : true
                },
                expired_date : {
                    required : true
                },
                coupon_price : {
                    required : true
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Training Coupon..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        disabledBeforeToday (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
