<template>
    <div>
        <tabs class="card">
            <tab title="SCHEDULE" icon="fa fa-clock-o">
                <Schedule />
            </tab>
            <tab title="COUPON" icon="fa fa-ticket">
                <Coupon />
            </tab>
        </tabs>
    </div>
</template>

<script>
import Schedule from './training-schedule/Schedule';
import Coupon from './training-coupon/Coupon';

export default {
    name       : 'EventTrainingDetails',
    components : { Schedule, Coupon }
};
</script>

<style scoped>

</style>
