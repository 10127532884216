<template>
    <div>
        <div class="fl-te-c pb-3">
            <h4 v-html="'TRAINING COUPON'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.trainingCouponAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Training Coupon" ref="trainingCouponAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddCoupon @success="formSuccess"></AddCoupon>
        </modal>

        <modal title="Edit Training Coupon" ref="trainingCouponEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditCoupon :initial-data="editingItem" @success="formSuccess"></EditCoupon>
        </modal>

        <delete-modal ref="trainingCouponDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Training Coupon <b v-html="deletingItem && deletingItem.coupon_name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../../data/urls';
import AddCoupon from './AddCoupon';
import EditCoupon from './EditCoupon';

export default {
    name       : 'Coupon',
    components : { AddCoupon, EditCoupon },
    data () {
        return {
            listUrl      : urls.admin.event.training.coupon.list + '?training=' + this.$route.params.id,
            deleteUrl    : urls.admin.event.training.coupon.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'coupon_name',
                    sortField  : 'coupon_name',
                    title      : 'Coupon Name',
                    titleClass : ''
                },
                {
                    name       : 'coupon_percentage',
                    sortField  : 'coupon_percentage',
                    title      : 'Coupon Percentage',
                    titleClass : ''
                },
                {
                    name       : 'expired_date',
                    sortField  : 'expired_date',
                    title      : 'Expired Date',
                    titleClass : ''
                },
                {
                    name       : 'coupon_price',
                    sortField  : 'coupon_price',
                    title      : 'Coupon Price',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.trainingCouponAddModal.close();
            refs.trainingCouponEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.trainingCouponEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.trainingCouponDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Training Coupon..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.trainingCouponDeleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
