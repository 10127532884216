<template>
    <div>
        <div class="fl-te-c pb-3">
            <h4 v-html="'TRAINING SCHEDULE'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.trainingScheduleAddModal.show()"
                     text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #disable="{rowData}">
                <checkbox-input v-if="validDate(rowData.start_date)" v-model="rowData.disable"
                                @input="disable(rowData)"></checkbox-input>
                <div v-show="scheduleStatus!==''">
                    {{ setScheduleStatus(rowData.start_date, rowData.end_date) }}
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn v-if="validDate(rowData.start_date)" @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Training Schedule" ref="trainingScheduleAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddSchedule @success="formSuccess"></AddSchedule>
        </modal>

        <modal title="Edit Training Schedule" ref="trainingScheduleEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditSchedule :initial-data="editingItem" @success="formSuccess"></EditSchedule>
        </modal>

        <delete-modal ref="trainingScheduleDeleteModal" :url="deleteUrl" :params="deletingItem"
                      @response="deleteComplete">
            <p>You are about to delete the Training Schedule <b v-html="deletingItem && deletingItem.location"></b>. Are
                you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../../data/urls';
import AddSchedule from './AddSchedule';
import EditSchedule from './EditSchedule';
import axios from 'secure-axios';

export default {
    name       : 'Schedule',
    components : { AddSchedule, EditSchedule },
    data () {
        return {
            scheduleStatus : '',
            listUrl        : urls.admin.event.training.schedule.list + '?training=' + this.$route.params.id,
            deleteUrl      : urls.admin.event.training.schedule.delete,
            editingItem    : null,
            deletingItem   : null,
            fields         : [
                {
                    name       : 'start_date',
                    sortField  : 'start_date',
                    title      : 'Start Date',
                    titleClass : ''
                },
                {
                    name       : 'end_date',
                    sortField  : 'end_date',
                    title      : 'End Date',
                    titleClass : ''
                },
                {
                    name       : 'class_start_time_i',
                    title      : 'Class Start Time',
                    titleClass : ''
                },
                {
                    name       : 'class_end_time_i',
                    title      : 'Class End Time',
                    titleClass : ''
                },
                {
                    name       : 'location',
                    sortField  : 'location',
                    title      : 'Location',
                    titleClass : ''
                },
                {
                    name       : '__slot:disable',
                    sortField  : 'disable',
                    title      : 'Disable',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        validDate (date) {
            const today = new Date();
            const selected = new Date(date);
            return selected > today;
        },
        setScheduleStatus (startDate, endDate) {
            const today = new Date();
            const start = new Date(startDate);
            const end = new Date(endDate);
            if (start < today && end > today) {
                this.scheduleStatus = 'Schedule Ongoing';
                return this.scheduleStatus;
            }
            if (end <= today) {
                this.scheduleStatus = 'Schedule Ended';
                return this.scheduleStatus;
            }
        },
        formSuccess () {
            const refs = this.$refs;
            refs.trainingScheduleAddModal.close();
            refs.trainingScheduleEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.trainingScheduleEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.trainingScheduleDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Training Schedule..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.trainingScheduleDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            axios.form(urls.admin.event.training.schedule.activateDeactivate, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify(json.message, 'Success',
                        {
                            type : 'success'
                        });
                } else {
                    that.$notify(json.message, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disable = !item.disable;
                }
            }).catch(function (err) {
                item.disable = !item.disable;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
